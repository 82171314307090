//@ts-nocheck
import React, { useState, useEffect } from "react";
import BoltIcon from "@mui/icons-material/Bolt";

export default function StationAlertPlate({alerts, clicked}) {
  const [currentAlertIndex, setCurrentAlertIndex] = useState(0);
  const [currentAlertColor, setCurrentAlertColor] = useState("bg-gray-500");

  const [currentAlert, setCurrentAlert] = useState();

  useEffect(() => {
    setCurrentAlert(alerts.alerts[currentAlertIndex]);
    
  }, [currentAlertIndex, alerts]);

  useEffect(() => {
    // Rotate alerts every 5 seconds
    const rotationIntervalId = setInterval(() => {
      setCurrentAlertIndex((prevIndex) =>
        prevIndex < alerts.alerts.length - 1 ? prevIndex + 1 : 0
      );
    }, 5000);

    // Cleanup the rotation interval on component unmount
    return () => clearInterval(rotationIntervalId);
  }, [alerts]);

  // Set the color based on the current alert type
  useEffect(() => {
    if (currentAlert?.event) {
      if (currentAlert.event.includes("Statement")) {
        setCurrentAlertColor("bg-yellow-500");
      } else if (currentAlert.event.includes("Watch")) {
        setCurrentAlertColor("bg-orange-500");
      } else if (currentAlert.event.includes("Warning")) {
        setCurrentAlertColor("bg-red-700");
      } else {
        setCurrentAlertColor("bg-gray-500");
      }
    }
  }, [currentAlert]);

  //${enabled ? "" : "hidden"}

  return (
    <div
      className={`transition-all duration-500 hover:brightness-50 hover:drop-shadow-lg cursor-pointer m-0 ${
        alerts?.alerts?.length > 0 ? "" : "hidden"
      }`}
      onClick={() => clicked(true)}>
      {currentAlert && (
        <div
          className={`bg-opacity-75 backdrop-blur-lg rounded-full flex items-center p-4 w-fit h-12 text-sm   lg:visible transition-all duration-1000 w-80 text-center truncate  ${currentAlertColor}`}>
          {currentAlert?.event}
        </div>
      )}
    </div>
  );
}
