//@ts-nocheck
export const getCurrentTime = (timestamp, timezone, long_en) => {

  if(!timezone){
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
    const short = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      timeZone: timezone,
    };

    const long = {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      timeZone: timezone,
    }

    if(isNaN(new Date(timestamp).getTime())){
      return "Unknown"
    }

    if(!long_en){  
      return new Date(timestamp).toLocaleTimeString('en-US', short);
    }
    else{
      return new Date(timestamp).toLocaleTimeString('en-US', long);
    }
  };
  