//@ts-nocheck
import React, { useRef, useEffect, useState } from "react";
import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import { updateStationsLayer } from "./stations_layer";
import { updateRadarLayers } from "./radar_layer";
import { updateSPCLayer } from "./spc_layer";
import { updateAlertsLayer } from "./alerts_layer";
import { updateSatelliteLayer } from "./satellite_layer";
import { compact } from "@headlessui/react/dist/utils/render";

import { MdOutlineRadar } from "react-icons/md";
import { MdAnimation } from "react-icons/md";
import { MdCrisisAlert } from "react-icons/md";
import { MdCellTower } from "react-icons/md";
import { MdOutlineWarningAmber } from "react-icons/md";
import { MdCloud } from "react-icons/md";

export default function Map({ options }) {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [mapLoaded, setMapLoaded] = useState(false);

  const [mapConfig, setMapConfig] = useState(options);

  const [radarTimestamp, setRadarTimestamp] = useState("RADAR INVALID");

  useEffect(() => {
    setMapConfig(options);
  }, [options]);

  useEffect(() => {
    setMapLoaded(false);
    // Initialize the map
    if (map.current) {
      // Update the map style if the map is already initialized
      const theme =
        mapConfig.theme === "dark" ? "/dark-map.json" : "/light-map.json";

      map.current.setStyle(theme);
      map.current.on("idle", () => {
        setTimeout(() => {
          setMapLoaded(true);
        }, 250);
      });
      return;
    }

    const initializeMap = async () => {
      const theme =
        mapConfig.theme === "dark" ? "/dark-map.json" : "/light-map.json";
      const map_interactive = mapConfig.interactive || false;
      const map_lat = mapConfig.lat || 40;
      const map_lon = mapConfig.lon || -95;
      const map_zoom = mapConfig.zoom || 3;
      var map_attribution = mapConfig.attribution;

      if(map_attribution == null){
        map_attribution = true;
      }

      map.current = new maplibregl.Map({
        container: mapContainer.current,
        style: theme,
        center: [map_lon, map_lat], // Initial longitude and latitude
        zoom: map_zoom, // Initial zoom level
        interactive: map_interactive,
        attributionControl: map_attribution
      });

      map.current.on("idle", () => {
        setTimeout(() => {
          setMapLoaded(true);
        }, 500);
      });
    };

    initializeMap();
  }, [mapConfig.theme]);

  useEffect(() => {
    if (mapLoaded) {
      updateStationsLayer(
        map.current,
        mapConfig.stations,
        mapConfig.station_data
      );
    }
  }, [mapLoaded, mapConfig.stations, mapConfig.station_data]);

  useEffect(() => {
    if (mapLoaded) {
      updateRadarLayers(
        map.current,
        mapConfig.radar || false,
        mapConfig.animate_radar || false,
        setRadarTimestamp
      );
    }
  }, [mapLoaded, mapConfig.radar, mapConfig.animate_radar]);

  useEffect(() => {
    if (mapLoaded) {
      updateSPCLayer(
        map.current,
        mapConfig.spc || false,
        mapConfig.spc_day || 1
      );
    }
  }, [mapLoaded, mapConfig.spc, mapConfig.spc_day]);

  useEffect(() => {
    if(mapLoaded){
    updateAlertsLayer(map.current, mapConfig.alerts || false);
    }
  }, [mapLoaded, mapConfig.alerts]);


  useEffect(() => {
    if(mapLoaded){
    updateSatelliteLayer(map.current, mapConfig.satellite || false);
    }
  }, [mapLoaded, mapConfig.satellite]);


  const handleRadarClick = () => {
    // Toggle radar setting
    setMapConfig((prevConfig) => {
      const newRadarState = !prevConfig.radar;
      return {
        ...prevConfig,
        radar: newRadarState,
      };
    });
  };

  const handleSatelliteCLick = () => {
    // Toggle radar animation setting
    setMapConfig((prevConfig) => {
      const newSatelliteState = !prevConfig.satellite;
      return {
        ...prevConfig,
        satellite: newSatelliteState,
      };
    });
  };

  const handleRadarAnimationClick = () => {
    // Toggle radar animation setting
    setMapConfig((prevConfig) => {
      const newRadarState = !prevConfig.animate_radar;
      return {
        ...prevConfig,
        animate_radar: newRadarState,
      };
    });
  };

  const handleSPCClick = () => {
    // Toggle radar animation setting
    setMapConfig((prevConfig) => {
      const newSPCstate = !prevConfig.spc;
      return {
        ...prevConfig,
        spc: newSPCstate,
      };
    });
  };

  const handleStationsCLick = () => {
    // Toggle radar animation setting
    setMapConfig((prevConfig) => {
      const newStationsState = !prevConfig.stations;
      return {
        ...prevConfig,
        stations: newStationsState,
      };
    });
  };

  const handleAlertsCLick = () => {
    // Toggle radar animation setting
    setMapConfig((prevConfig) => {
      const newAlertsState = !prevConfig.alerts;
      return {
        ...prevConfig,
        alerts: newAlertsState,
      };
    });
  };

  return (
    <div className="w-full h-full relative">
      <div
        className={`absolute top-1 right-1 flex flex-row z-20 ${
          mapConfig.controls ? "" : "hidden"
        }`}>
        <MdOutlineRadar
          className={`${
            mapConfig.radar ? "text-white" : "text-gray-400"
          } hover:bg-gray-500 hover:cursor-pointer	rounded-lg p-2`}
          size={48}
          onClick={handleRadarClick}
        />
        <MdCloud
          className={`${
            mapConfig.satellite ? "text-white" : "text-gray-400"
          } hover:bg-gray-500 hover:cursor-pointer	rounded-lg p-2`}
          size={48}
          onClick={handleSatelliteCLick}
        />
        <MdAnimation
          className={`${
            mapConfig.animate_radar ? "text-white" : "text-gray-400"
          } hover:bg-gray-500 hover:cursor-pointer	rounded-lg p-2`}
          size={48}
          onClick={handleRadarAnimationClick}
        />
        <MdCrisisAlert
          className={`${
            mapConfig.spc ? "text-white" : "text-gray-400"
          } hover:bg-gray-500 hover:cursor-pointer	rounded-lg p-2`}
          size={48}
          onClick={handleSPCClick}
        />
        <MdOutlineWarningAmber 
          className={`${
            mapConfig.alerts ? "text-white" : "text-gray-400"
          } hover:bg-gray-500 hover:cursor-pointer	rounded-lg p-2`}
          size={48}
          onClick={handleAlertsCLick}
        />
        <MdCellTower
          className={`${
            mapConfig.stations ? "text-white" : "text-gray-400"
          } hover:bg-gray-500 hover:cursor-pointer	rounded-lg p-2`}
          size={48}
          onClick={handleStationsCLick}
        />
        
      </div>
      <div ref={mapContainer} className="h-full w-full" />
      <div className={`absolute left-0 top-0 p-2 text-white text-xs backdrop-brightness-50 rounded-br-lg overflow-hidden z-40 ${mapConfig.showRadarTimestamp? '': 'hidden'}`}>
        {radarTimestamp}
      </div>
    </div>
  );
}
