//@ts-nocheck

export const updateSPCLayer = async (map, enabled, day) => {
    const updateSPC = () => {
      // Check if the source already exists
      if (map.getSource("spc")) {
        // Update the source data
        //map.getSource("spc").setData(`https://maps-api.wxlogic.com/products/spc/outlook/day${Math.round(day) || 1}.json`);
      } else {
        // Add the source if it doesn't exist
        map.addSource("spc", {
          type: "geojson",
          data: `https://maps-api.wxlogic.com/products/spc/outlook/day${Math.round(day) || 1}.json`,
        });
      }
  
      // Check if the layer already exists
      if (!map.getLayer("spc")) {
        map.addLayer(
          {
            id: "spc",
            type: "fill",
            source: "spc",
            paint: {
              "fill-color": ["get", "fill"],
              "fill-outline-color": ["get", "stroke"],
              "fill-opacity": 0.5,
            },
          },
          "aeroway-area"
        );
      }
    };
  
    
  
    if (enabled) {
        
        
        updateSPC();
        
     
    } else {
      if (map.getLayer("spc")) {
        map.removeLayer("spc");
      }
      
    }
  };
  