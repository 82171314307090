//@ts-nocheck
import React, { useEffect } from "react";


export default function ImagePlate({ sponsor, config }) {
  useEffect(() => {
    //console.log(sponsor);
  }, [sponsor]);

  if (sponsor) {
    return (
      <a href={sponsor.link}>
        <div
          style={{ backgroundColor: sponsor.color }}
          className={`relative flex justify-center items-center border ${config.panel_height} ${config.panel_width} bg-black bg-opacity-70 rounded-md ${config.panel_padding} ${config.panel_margin} transition-all duration-1000 hover:brightness-50 hover:drop-shadow-lg cursor-pointer`}
        >
          <img
            className="max-h-full max-w-full object-contain"
            src={sponsor.logo}
            alt="Sponsor Logo"
          />
        </div>
      </a>
    );
  } else {
    return null;
  }
}

