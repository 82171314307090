//@ts-nocheck

import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import StationPlate from "./DashboardPlates/StationPlate";
import ControllableMap from "./Map/controllable_map";
import TopViewed from "./DashboardPlates/TopViewed";
import { MdBrightness6 } from "react-icons/md";
import { MdLogin } from "react-icons/md";

import { debounce } from 'lodash';
import { Link } from "react-router-dom";
import { Satellite } from "@mui/icons-material";

const getSystemTheme = () => {
  const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
  return prefersDark ? 'dark' : 'light';
};

function Home() {
  const [stations, setStations] = useState([]);
  const [error, setError] = useState(null);
  const [theme, setTheme] = useState(() => {
    return localStorage.getItem('theme') || getSystemTheme();
  });
  const [searchTerm, setSearchTerm] = useState('');

  

  // Fetch stations data
  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://realtime.hazcams.wxlogic.com/api/stations?id=" + Date.now()
      );
      console.log("Fetched Stations:", response.data); // Debugging line
      setStations(response.data);
    } catch (error) {
      setError(error);
    }
  };

  // Fetch data on mount and set interval
  useEffect(() => {
    document.title = "Hazcams";
    fetchData();
    const interval = setInterval(fetchData, 60000*15);
    return () => clearInterval(interval);
  }, []);

  // Apply the theme class to the body or root element
  useEffect(() => {
    document.documentElement.classList.toggle("dark", theme === "dark");
    localStorage.setItem('theme', theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme(theme === "light" ? "dark" : "light");
  };

  // Debounce search input to improve performance
  const debouncedSearch = useCallback(
    debounce((term) => setSearchTerm(term), 300),
    []
  );

  // Filter and sort stations based on search term and viewers
 

  // Debugging line to ensure searchTerm and filteredStations are correct
  //console.log("Search Term:", searchTerm);
  //console.log("Filtered Stations:", filteredStations);

  return (
    <div className="bg-white dark:bg-gray-900 min-h-screen">
      <div className="w-full h-full bg-gray-100 dark:bg-gray-800 ">
        <div className="w-full h-16 bg-gray-200 dark:bg-gray-600 flex flex-row justify-between items-center px-6 py-2 ">
          <div className="flex flex-col justify-end left-side text-gray-800 dark:text-white">
            <p className="font-black text-xl">Hazcams</p>
            <p className="text-xs">By WXLOGIC</p>
          </div>
          <div className="flex flex-row">
          <div
            className="text-gray-800 dark:text-white hover:bg-gray-500 p-2 rounded-md cursor-pointer"
            onClick={toggleTheme}
          >
            <MdBrightness6 size={24} />
          </div>
          <Link
          to="https://app.hazcams.com"
          className="text-gray-800 dark:text-white hover:bg-gray-500 p-2 rounded-md cursor-pointer"
          >
            <MdLogin size={24} />
          </Link>
          </div>
        </div>
        <div className="body-container flex flex-col">
          <div className="flex flex-col lg:flex-row">
            <div className="flex basis-full lg:basis-1/4 bg-gray-400 rounded-lg overflow-hidden m-2 lg:mr-1 aspect-square">
              <TopViewed stations={stations} />
            </div>
            <div className="flex basis-full lg:basis-3/4 bg-gray-400 rounded-lg overflow-hidden aspect-square lg:aspect-auto m-2 lg:ml-1">
              <ControllableMap
                options={{
                  interactive: true,
                  controls: true,
                  theme: theme,
                  radar: true,
                  spc: true,
                  spc_day: 1,
                  animate_radar: false,
                  stations: true,
                  station_data: stations,
                  alerts: true,
                  satellite: false
                }}
              />
            </div>
          </div>

          {/* Search Bar */}
          <div className="px-2 pb-2 flex flex-row">
            <input
              type="text"
              placeholder="Search stations..."
              onChange={(e) => debouncedSearch(e.target.value)}
              className="w-full p-2 border dark:border-gray-800 rounded-md bg-white dark:bg-gray-600 dark:text-white"
            />
          </div>

          {/* Grid of Station Plates */}
          <div className="flex flex-col lg:flex-row">
            <div className="grid grid-cols-2 md:grid-cols-3 2xl:grid-cols-5 gap-2 px-2 w-full">
              {stations.length > 0 ? (
                stations.map((station) => (
                  <StationPlate
                    station={station}
                  />
                ))
              ) : (
                <p className="col-span-full text-center text-gray-500">No stations found</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;

