//@ts-nocheck
import React, { useEffect, useState } from "react";
import UmbrellaIcon from '@mui/icons-material/Umbrella';
import LoadingPlate from "./loading_plate";
import { interpolateColor, lightenColor, createLinearGradient } from '../utils/colorUtils';

const colorArray = [[[0,[89,89,89,255]],
[1,[90,88,101,255]],
[5,[97,88,132,255]],
[10,[52,117,142,255]],
[30,[11,140,129,255]],
[40,[92,153,100,255]],
[80,[159,157,84,255]],
[120,[170,101,59,256]],
[500,[255,0,90,256]],
[8000,[228,0,255,256]]]];

// Type Definitions
interface panelData {
  value: string | null;
  config;
  trendline: number[] | null;
  range: { min: number; max: number } | undefined;
}

interface RainDayPlateProps {
  stationId: string;
}

// Constants
const rangeMultiplier = 5;


// Component
const RainDayPlate: React.FC<RainDayPlateProps> = ({
  stationId,
  config
}) => {
  // State
  const [panelData, setpanelData] = useState<panelData>({
    value: null,
    trendline: null,
    range: undefined,
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  

  // Fetch Lightning Data
  useEffect(() => {
    const fetchData = () => {
      fetch(
        `https://realtime.hazcams.wxlogic.com/api/station/rain/${stationId}`
      )
        .then((response) => response.json())
        .then((data: panelData) => {
          setpanelData(data);
          setLoading(false);
        })
        .catch((error) => {
          setError("Error fetching rain data");
          setLoading(false);
        });
    };
  
    // Fetch data initially
    fetchData();
  
    // Fetch data every minute
    const interval = setInterval(fetchData, 60000); // 60000 milliseconds = 1 minute
  
    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, [stationId]);

  

  
  // Render
  if (loading)
    return null;
  if (error) return null;
  if (!panelData.value || !panelData.trendline) {
    //return <LoadingPlate/>; // Placeholder or loading indicator
    return null //Used for non forced panels
  }

  // Data Preparation
  const step = 100 / (panelData.trendline.length - 1);
  const linePath = panelData.trendline
    .map((value, index) => {
      const x = (index * step).toFixed(2);
      const y = (
        100 -
        ((value - (panelData.range.min - rangeMultiplier)) /
          (rangeMultiplier +
            panelData.range.max -
            (panelData.range.min - rangeMultiplier))) *
          100
      ).toFixed(2);
      return `${x} ${y}`;
    })
    .join(" ");

  // Rendering

  const color = interpolateColor((Number(panelData?.value * 25.4  ) || 0
  ), colorArray);
  const linearGradient = createLinearGradient(
    color,
    Number(panelData.value),
    Number(panelData.range.min),
    Number(panelData.range.max)
  );

  return (
    <div
    className={`relative ${config.panel_height} grid items-center bg-black bg-opacity-70 rounded-md ${config.panel_padding} ${config.panel_margin}  transition-all duration-1000 hover:brightness-50 hover:drop-shadow-lg cursor-pointer`}
    style={{
        background: `${lightenColor(color, -40)}`,
        position: "relative",
      }}>
      <svg
        className="absolute inset-0 rounded-md"
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
        style={{ width: "100%", height: "100%" }}>
        <polygon
          points={`0 100, ${linePath}, 100 100`}
          fill={color}
          opacity="1"
        />
      </svg>
      <div className="absolute left-2 flex items-center text-white z-10">
        <UmbrellaIcon fontSize="medium" />
      </div>
      <div className="absolute right-2 text-3xl text-right z-10">
        {panelData.value}
      </div>
      <div className="text-xs text-right absolute bottom-0 inset-x-0 p-1 z-10">
        IN
      </div>
      <div className="text-xs text-left absolute bottom-0 inset-x-0 p-1 z-10">
        24 HR
      </div>
      <div className="text-xs text-left absolute top-0 inset-x-0 p-1 z-10">
        Total Rainfall
      </div>
    </div>
  );
};

export default RainDayPlate;
