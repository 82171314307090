//@ts-nocheck

import { getCurrentTime } from "../utils/timeUtils";

let radarAnimationInterval; // Variable to store the interval ID
let updateInterval; // Variable to store the interval for checking updates
let currentIndex = 0; // Track the current frame index

function formatUTCtoLocal(utcTimestamp) {
    // Parse the UTC timestamp into a Date object
    const date = new Date(utcTimestamp + 'Z'); // Adding 'Z' to treat it as UTC

    // Extract date and time components
    const month = date.getMonth() + 1; // Months are zero-indexed
    const day = date.getDate();
    const year = date.getFullYear().toString().slice(-2); // Get last 2 digits of the year
    const hour = date.getHours() % 12 || 12; // Convert 24-hour time to 12-hour time
    const minute = date.getMinutes().toString().padStart(2, '0'); // Pad minutes with leading zero if necessary
    const period = date.getHours() >= 12 ? 'PM' : 'AM'; // Determine AM/PM

    // Format the time in MM/DD/YY HH:MM PM
    const formattedTime = `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year} ${hour}:${minute} ${period}`;

    return formattedTime;
}






export const updateRadarLayers = async (map, enabled, animated, callback) => {
    if (enabled) {

        if (!updateInterval) {
            updateInterval = setInterval(() => {
                updateRadarLayers(map, enabled, animated, callback);
            }, 30000); // 30,000 ms = 30 seconds
        }


        try {
            // Fetch the latest radar data
            const response = await fetch(
                `https://maps-api.wxlogic.com/data/current/1?id=${new Date().toISOString()}`
            );
            const data = await response.json();

            // Use only the first 10 entries or fewer if there are not 10
            const entries = data.slice(-10);

            // Preload all layers
            entries.forEach((entry, index) => {
                const sourceId = `radar-tiles-${index}`;
                const layerId = `radar-layer-${index}`;
                const tileUrl = `https://maps-api.wxlogic.com/tileserver/${entry.identifier}/{z}/{x}/{y}.png?apiKey=d76038e92ab1e1a586cbb5ef122efa9d`;

                // Add the source if it doesn't exist
                if (!map.getSource(sourceId)) {
                    map.addSource(sourceId, {
                        type: 'raster',
                        tiles: [tileUrl],
                        tileSize: 256,
                    });
                }

                // Add the layer if it doesn't exist
                if (!map.getLayer(layerId)) {
                    map.addLayer(
                        {
                            id: layerId,
                            type: 'raster',
                            source: sourceId,
                            paint: {
                                'raster-opacity': 0.75,
                            },
                            layout: {
                                visibility: 'none', // Initially hidden
                            },
                        },
                        'road_secondary_tertiary'
                    );
                }
            });

            if (animated) {
                // Clear any existing interval before setting a new one
                if (radarAnimationInterval) {
                    clearInterval(radarAnimationInterval);
                }

                // Initialize the currentIndex to the latest frame index
                currentIndex = entries.length - 1;

                radarAnimationInterval = setInterval(() => {
                    // Show the current layer
                    const currentLayerId = `radar-layer-${currentIndex}`;
                    const currentEntry = entries[currentIndex]; // Get current entry
                    const latestEntry = entries[entries.length - 1];
                    if (map.getLayer(currentLayerId)) {
                        map.setLayoutProperty(currentLayerId, 'visibility', 'visible');
                    }

                    // Remove the previous layer, if applicable
                    const previousIndex = (currentIndex === 0) ? entries.length - 1 : currentIndex - 1;
                    const previousLayerId = `radar-layer-${previousIndex}`;
                    if (map.getLayer(previousLayerId)) {
                        map.setLayoutProperty(previousLayerId, 'visibility', 'none');
                    }

                    // Invoke the callback with the current radar time in UTC
                    if (callback && typeof callback === 'function') {
                        callback(formatUTCtoLocal(latestEntry.timestamp));
                    }

                    // Move to the next index
                    currentIndex = (currentIndex + 1) % entries.length;
                }, 1000); // Adjust the interval as needed for animation speed
            } else {
                // Show the latest frame if animation is disabled
                if (radarAnimationInterval) {
                    clearInterval(radarAnimationInterval);
                    radarAnimationInterval = null; // Clear the interval ID
                }

                map.getStyle().layers.forEach(layer => {
                    if (layer.id.startsWith('radar-layer-')) {
                        map.setLayoutProperty(layer.id, 'visibility', 'none');
                    }
                });

                // Ensure the latest frame is visible
                if (entries.length > 0) {
                    const latestLayerId = `radar-layer-${entries.length - 1}`;
                    const latestEntry = entries[entries.length - 1];
                    if (map.getLayer(latestLayerId)) {
                        map.setLayoutProperty(latestLayerId, 'visibility', 'visible');
                    }
               
                    // Invoke the callback with the latest radar time in UTC
                    if (callback && typeof callback === 'function') {
                        callback(formatUTCtoLocal(latestEntry.timestamp));
                    }
                }
            }

            // Remove any old layers and sources that are outside the 0-9 range
            for (let i = 10; i < data.length; i++) {
                const layerId = `radar-layer-${i}`;
                const sourceId = `radar-tiles-${i}`;

                if (map.getLayer(layerId)) {
                    map.removeLayer(layerId);
                }
                if (map.getSource(sourceId)) {
                    map.removeSource(sourceId);
                }
            }
        } catch (error) {
            console.error('Error fetching radar data:', error);
        }
    } else {

        if (radarAnimationInterval) {
            clearInterval(radarAnimationInterval);
            radarAnimationInterval = null;
        }

        // Disable and remove layers if disabled
        if (radarAnimationInterval) {
            clearInterval(radarAnimationInterval);
            radarAnimationInterval = null; // Clear the interval ID
        }

        for (let i = 0; i < 10; i++) {
            const layerId = `radar-layer-${i}`;
            const sourceId = `radar-tiles-${i}`;

            if (map.getLayer(layerId)) {
                map.removeLayer(layerId);
            }
            if (map.getSource(sourceId)) {
                map.removeSource(sourceId);
            }
        }
    }
};

