//@ts-nocheck
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MdPeople } from "react-icons/md";
import { calcAlertFill } from "../utils/colorUtils";
import { MdOutlineWarningAmber } from "react-icons/md";

export default function StationPlate(station) {
  station = station.station;

  return (
    <Link
      to={`/station/${station.station_id}`}
      className="relative w-full aspect-square rounded-lg overflow-hidden transition-all duration-500 dark:border-gray-500 dark:border ">
      <div className="relative w-full h-full">
        <object
          className="absolute top-0 left-0 w-full h-full object-cover brightness-90 hover:delay-0 delay-300 hover:brightness-125 duration-1000 hover:duration-100 transition-all"
          data={`https://hazcams-data-prod.nyc3.digitaloceanspaces.com/thumbnails/${station.station_id}/medium.jpg?cache=${Date.now()}`}
          type="image/jpg">
          <img
            className="absolute top-0 left-0 w-full h-full object-cover"
            src="/fallback_thumbnail.jpg"
            alt="Station snapshot"
          />
        </object>
        <div className={`absolute top-0 left-0 w-full h-full bg-gradient-to-b from-black via-transparent to-transparent pointer-events-none ${station.station_online? ('') : ('backdrop-grayscale backdrop-blur-sm')}`}></div>
      </div>

      <div className="w-full h-full z-10 text-white  backdrop-blur-none p-1">
        <div className="absolute flex flex-col top-2 left-2">
          <div className="text-xl text-left pr-14">{station.station_name}</div>
          <div className="text-xs text-left pr-14 overflow-hidden line-clamp-4">
            {station.station_description}
          </div>
        </div>
        <div className="absolute flex flex-col top-2 right-2 items-end">
          <div className="bg-gray-500 rounded-full text-white text-xs py-1 px-3 m-1 w-fit">
            {station.station_online ? (
              <div className="flex flex-row">
                {station.station_viewers}{" "}
                <MdPeople size={16} className="ml-1" />
              </div>
            ) : (
              <p>OFFLINE</p>
            )}
          </div>
        </div>

        <div className="absolute flex flex-col bottom-1 left-2 m-1 items-start 	 ">
          {station.station_alerts.alerts.map((alert) => (
            <div className="rounded-full bg-red-800 text-white text-sm py-1 px-3 m-1 w-fit hidden md:block ">
              {alert.event}
            </div>
          ))}
          {station.station_alerts.alerts.length > 0 ? (
            <MdOutlineWarningAmber
              className="block md:hidden  text-red-600"
              size={32}
            />
          ) : null}
        </div>

        <div
          className="absolute bottom-1 right-1 flex w-24 h-12 p-2 items-center bg-opacity-70 rounded-md m-2 justify-between"
          style={{ backgroundColor: station.station_sponsor.color }}>
          <img
            className="w-full h-full object-contain"
            src={station.station_sponsor.logo}
            alt="Sponsor Logo"
          />
        </div>
      </div>
    </Link>
  );
}
