import React, { useState, useEffect } from "react";

interface Props {
  loadingText: string;
  loadingImage: string;
}

const LoadingPage: React.FC<Props> = ({ loadingText, loadingImage }) => {
  // Style object for the outermost div with the background image
  const containerStyle: React.CSSProperties = {
    backgroundImage: `url(${loadingImage + "?id=" + Date.now()})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  };

  return (
    <div className="h-screen w-screen" style={containerStyle}>
      <div className="backdrop-blur-md flex flex-col justify-center items-center h-screen text-white">
        <div>
          <p className="text-2xl text-center">WXLOGIC</p>
          <p className="text-small text-center">{loadingText}</p>
        </div>
      </div>
    </div>
  );
};

export default LoadingPage;
