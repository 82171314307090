//@ts-nocheck

const calcAlertFill = (type) => {
    if (type == "Severe Thunderstorm Warning") {
      return "#dd6b20";
    } else if (type == "Tornado Warning") {
      return "#e53e3e";
    } else if (type == "Flash Flood Warning") {
      return "#38a169";
    } else if (type == "Special Weather Statement") {
      return "#ffe4b5";
    } else if (type == "Winter Storm Watch") {
      return "#03b0d0";
    } else if (type == "Winter Weather Advisory") {
      return "#7B68EE";
    } else if (type == "Winter Storm Warning") {
      return "#FF69B4";
    } else if (type == "Lake Effect Snow Warning") {
      return "#008B8B";
    }
    return "#000";
  };
  
  const makeZoneFilterAndColorExpression = (zoneAlerts) => {
    var zoneFilter = ["in", "ugc"];
    var colorExpression = ["match", ["get", "ugc"]];
  
    zoneAlerts.forEach((alert) => {
      for (var i = 0; i < alert.zones.length; i++) {
        let alertColor = calcAlertFill(alert.event);
        if (!zoneFilter.includes(alert.zones[i])) {
          zoneFilter.push(alert.zones[i]);
          colorExpression.push(alert.zones[i]);
          colorExpression.push(alertColor);
        }
      }
    });
  
    colorExpression.push("#ccc");
  
    return { zoneFilter, colorExpression };
  };
  
  let flashInterval;
  let updateInterval;
  
  const updateAlerts = async (map) => {
    try {
      const response = await fetch("https://api.wxlogic.com/v1/alerts/active");
      const data = await response.json();
      let alerts = data;
      let zoneAlerts = [];
      let featuresCollection = {
        type: "FeatureCollection",
        features: [],
      };
  
      alerts.forEach((alert) => {
        if (alert.polygon) {
          featuresCollection.features.push({
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [alert.polygon],
            },
            properties: {
              ...alert,
              fill: calcAlertFill(alert.event),
            },
          });
        } else {
          zoneAlerts.push(alert);
        }
      });
  
      if (!map.getSource("polygonAlertsSource")) {
        map.addSource("polygonAlertsSource", {
          type: "geojson",
          data: featuresCollection,
        });
      } else {
        map.getSource("polygonAlertsSource").setData(featuresCollection);
      }
  
      if (!map.getLayer("polygon-alerts-fill")) {
        map.addLayer(
          {
            id: "polygon-alerts-fill",
            type: "fill",
            source: "polygonAlertsSource",
            paint: {
              "fill-color": { type: "identity", property: "fill" },
              "fill-opacity": 0.3,
            },
          },
          "road_trunk_primary"
        );
      }
  
      if (!map.getLayer("polygon-alerts-poly")) {
        map.addLayer(
          {
            id: "polygon-alerts-poly",
            type: "line",
            source: "polygonAlertsSource",
            paint: {
              "line-color": { type: "identity", property: "fill" },
              "line-opacity": 1,
              "line-width": 2,
            },
          },
          "admin_country_z5-"
        );
  
        // Add flashing effect
        let isVisible = true;
        flashInterval = setInterval(() => {
          isVisible = !isVisible;
          if (map.getLayer("polygon-alerts-poly")) {
            map.setPaintProperty(
              "polygon-alerts-poly",
              "line-opacity",
              isVisible ? 1 : 0
            );
          }
        }, 500); // Change interval as needed
      }
  
      if (!map.getLayer("zone-alerts")) {
        if (map.getSource("zone-alerts")) {
          map.removeSource("zone-alerts");
        }
        map.addLayer(
          {
            id: "zone-alerts",
            type: "fill",
            "source-layer": "mappingzones",
            source: {
              type: "vector",
              tiles: [
                "https://tiles.wxlogic.com/data/zones/{z}/{x}/{y}.pbf",
              ],
            },
            paint: {
              "fill-color": "#fff",
              "fill-opacity": 0.6,
            },
            filter: ["all", ["in", "ugc"], ["==", "type", "z"]],
          },
          "road_trunk_primary"
        );
      }
  
      let { zoneFilter, colorExpression } =
        makeZoneFilterAndColorExpression(zoneAlerts);
  
      if (zoneFilter.length !== 0) {
        map.setFilter("zone-alerts", [
          "all",
          zoneFilter,
          ["in", "type", "c", "z", "mz", "oz"],
        ]);
        map.setPaintProperty("zone-alerts", "fill-color", colorExpression);
      }
    } catch (error) {
      console.error("Error fetching alert data:", error);
    }
  };
  
  export const updateAlertsLayer = async (map, enabled) => {
    if (enabled) {
      updateAlerts(map); // Initial call
      updateInterval = setInterval(() => {
        updateAlerts(map);
      }, 30000); // Update every 30 seconds
    } else {
      // Clear the intervals when disabling the layer
      if (flashInterval) {
        clearInterval(flashInterval);
      }
  
      if (updateInterval) {
        clearInterval(updateInterval);
      }
      
      if (map.getLayer("polygon-alerts-fill")) {
        map.removeLayer("polygon-alerts-fill");
      }
  
      if (map.getLayer("polygon-alerts-poly")) {
        map.removeLayer("polygon-alerts-poly");
      }
  
      if (map.getLayer("zone-alerts")) {
        map.removeLayer("zone-alerts");
      }
  
      
    }
  };
  