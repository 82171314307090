//@ts-nocheck
import React, { useState, useEffect, useRef } from "react";

import Map from "../Map/controllable_map";

import WarningIcon from "@mui/icons-material/Warning";
import FloodIcon from "@mui/icons-material/Flood";
import ThunderstormIcon from "@mui/icons-material/Thunderstorm";
import AirIcon from "@mui/icons-material/Air";
import FilterDramaIcon from "@mui/icons-material/FilterDrama";
import SevereColdIcon from "@mui/icons-material/SevereCold";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import CycloneIcon from "@mui/icons-material/Cyclone";
import TornadoIcon from "@mui/icons-material/Tornado";

import { getCurrentTime } from "../utils/timeUtils";

const defaultIcon = <WarningIcon />;

const eventIconMap = {
  "Coastal Flood Watch": <FloodIcon />,
  "Coastal Flood Warning": <FloodIcon />,
  "Coastal Flood Advisory": <FloodIcon />,
  "Flood Watch": <FloodIcon />,
  "Flash Flood Warning": <FloodIcon />,
  "Flood Warning": <FloodIcon />,
  "River Flood Watch": <FloodIcon />,
  "River Flood Warning": <FloodIcon />,

  "High Wind Watch": <AirIcon />,
  "High Wind Warning": <AirIcon />,
  "Wind Advisory": <AirIcon />,
  "Extreme Wind Warning": <AirIcon />,

  "Severe Thunderstorm Watch": <ThunderstormIcon />,
  "Severe Thunderstorm Warning": <ThunderstormIcon />,

  "Tornado Watch": <TornadoIcon />,
  "Tornado Warning": <TornadoIcon />,

  "Dense Fog Advisory": <FilterDramaIcon />,

  "Winter Storm Watch": <SevereColdIcon />,
  "Blizzard Warning": <SevereColdIcon />,
  "Winter Storm Warning": <SevereColdIcon />,
  "Ice Storm Warning": <SevereColdIcon />,
  "Winter Weather Advisory": <SevereColdIcon />,
  "Freeze Watch": <SevereColdIcon />,
  "Freeze Warning": <SevereColdIcon />,
  "Frost Advisory": <SevereColdIcon />,
  "Wind Chill Advisory": <SevereColdIcon />,
  "Wind Chill Warning": <SevereColdIcon />,

  "Fire Weather Watch": <LocalFireDepartmentIcon />,
  "Red Flag Warning": <LocalFireDepartmentIcon />,

  "Excessive Heat Watch": <WbSunnyIcon />,
  "Excessive Heat Warning": <WbSunnyIcon />,
  "Heat Advisory": <WbSunnyIcon />,

  "Tropical Storm Watch": <CycloneIcon />,
  "Tropical Storm Warning": <CycloneIcon />,
  "Hurricane Watch": <CycloneIcon />,
  "Hurricane Warning": <CycloneIcon />,

  // Add more event types and icons as needed
};

export default function AlertModal({
  isOpen,
  closeModal,
  stationId,
  stationName,
  stationLocation,
  alerts,
  theme
}) {
  const [displayedAlerts, setDisplayedAlerts] = useState();

  useEffect(() => {
    const alertEntries = [];

    const dateOptions = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
      timeZoneName: "short",
    };

    for (var alert in alerts.alerts) {
      console.log(alerts.alerts[alert]);
      alertEntries.push(
        <div className="bg-gray-100 dark:bg-gray-500 dark:text-white w-full  rounded-lg overflow-hidden">
          <div className="bg-gray-300 dark:bg-gray-600 dark:text-white w-full flex flex-col p-4 ">
            <div className="">
              {eventIconMap[alerts.alerts[alert].event] || defaultIcon}
            </div>
            <div className="text-lg">
              {alerts.alerts[alert].event}
            </div>
            <div className="text-xs
            ">
              Expires{" "}
              {new Date(alerts.alerts[alert].expires).toLocaleString(
                undefined,
                dateOptions
              )}
            </div>
          </div>
          <div className="p-4  text-xs lg:text-base">
            <p>{alerts.alerts[alert].headline}</p>
            <br />
            <p className="whitespace-pre text-wrap">
              {alerts.alerts[alert].description}
            </p>
            <br />
            <p>{alerts.alerts[alert].instruction}</p>
          </div>
        </div>
      );
    }
    setDisplayedAlerts(alertEntries);
  }, [alerts]);

  if (!isOpen) {
    return null;
  }

  return (
    <div
      id="default-modal"
      tabIndex="-1"
      aria-hidden="true"
      className="overflow-y-auto overflow-x-hidden fixed inset-0 z-50 flex justify-center p-6 items-center bg-black bg-opacity-50 backdrop-blur-lg cursor-pointer rounded-lg">
      <div className="relative bg-white dark:bg-gray-800  shadow-md w-full max-w-5xl cursor-default rounded-lg">
        <div className="flex justify-between p-4 md:p-5  ">
          <div className="flex flex-col text-gray-900 dark:text-white">
          <h3 className="text-xl ">
            NWS Alerts for {stationName}
          </h3>
          <p>Last Updated {getCurrentTime(alerts.timestamp, null, true)}</p>
          </div>
          <button
            type="button"
            className="text-gray-400 dark:text-white font-sans bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center"
            data-modal-hide="default-modal"
            onClick={closeModal}>
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14">
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
        </div>
        
          <div className="p-2  space-y-2 max-h-[600px] overflow-y-scroll  noscrollbar">
            {displayedAlerts}
          </div>
          
       
      </div>
    </div>
  );
}
