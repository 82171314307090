//@ts-nocheck

export const updateSatelliteLayer = async (map, enabled) => {
  const updateSatellite = () => {
      // Check if the source already exists
      if (map.getSource("satellite")) {
          // Update the source data if needed
          // map.getSource("satellite").setData(`URL_TO_YOUR_SATELLITE_SOURCE`);
      } else {
          // Add the source if it doesn't exist
          map.addSource("satellite", {
              type: "raster",
              tiles: [
                  `https://realtime.hazcams.wxlogic.com/api/satellite/{z}/{x}/{y}.png`
              ],
              tileSize: 256,
          });
      }

      // Check if the layer already exists
      if (!map.getLayer("satellite")) {
          map.addLayer(
              {
                  id: "satellite",
                  type: "raster",
                  source: "satellite",
                  paint: {
                      "raster-opacity": 0.75,
                  },
              },
              "aeroway-area" // Ensure this is a valid layer to place your satellite layer above
          );
      }
  };

  if (enabled) {
      updateSatellite();
  } else {
      if (map.getLayer("satellite")) {
          map.removeLayer("satellite");
      }
  }
};
