//@ts-nocheck
import React from "react";
import { getCurrentTime } from "../utils/timeUtils.tsx";
import { Link } from "react-router-dom";

export default function TopViewed({ stations }) {
  // Get the first 5 stations
  const topStations = stations.slice(0, 4);

  return (
    <div className="w-full h-full bg-gray-200 dark:bg-gray-600">
      <div className="flex flex-row bg-gray-300 dark:bg-gray-500 w-full h-12 items-center py-2 px-6 text-gray-800 dark:text-white">
        <p className="text-lg font-bold">Top Stations</p>
      </div>
      <div className="flex flex-col h-[calc(100%-3rem)] py-1 overflow-hidden">
        {topStations.map((station, index) => (
          <Link
          to={`/station/${station.station_id}`}
          state={{ img: station.station_snapshot }}
          key={index}
          className="flex flex-row justify-between rounded-lg items-center m-1 bg-gray-200 dark:bg-gray-700 overflow-hidden outline outline-1 outline-gray-400 hover:brightness-75 cursor-pointer transition-colors ease-out duration-500 h-1/4">
          
          {/* Image Wrapper */}
          <div className="w-1/3 h-full">
            <object
              className="h-full w-full object-cover"
              data={`https://hazcams-data-prod.nyc3.digitaloceanspaces.com/thumbnails/${station.station_id}/medium.jpg?cache=${Date.now()}`}
              type="image/jpg">
              <img
                className="h-full w-full object-cover"
                src="/fallback_thumbnail.jpg"
                alt="Station snapshot"
              />
            </object>
          </div>
            <div className="flex flex-col mx-4 text-right basis-3/4 overflow-hidden text-gray-700 dark:text-white">
              <p className="text-lg font-semibold -my-1 text-ellipsis line-clamp-1 overflow-hidden">
                {station.station_name}
              </p>
              <p className="text-md font-semibold -my-0.5">
                {station.station_viewers} Viewers
              </p>
              <p className="text-sm font-semibold -my-0.5">
                {station.station_sponsor.name}
              </p>
              <p className="text-xs font-semibold -my-0.5">
                {getCurrentTime(new Date(), station.station_timezone)}
              </p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}
