//@ts-nocheck
import maplibregl from "maplibre-gl";
import { getCurrentTime } from "../utils/timeUtils.tsx";
import { MdOutlineWarningAmber } from "react-icons/md";

// Function to create a custom HTML marker
// Maintain a global array to track markers
const markers = [];

const createStyledMarker = (station) => {
  const markerElement = document.createElement("div");
  markerElement.className = "custom-marker"; // Add custom class for styling

  markerElement.innerHTML = `
    <a href="/station/${
      station.station_id
    }" target="_blank" class="relative inline-block bottom-2">
      <div class="absolute flex flex-row bottom-0 left-1/2 transform -translate-x-1/2 bg-white dark:bg-gray-500 text-black dark:text-white rounded-lg overflow-hidden text-center h-12 hover:h-24 group hover:w-60 hover:z-60 w-12 transition-all duration-300 mb-1 ${
        station.station_online
          ? ""
          : "ring ring-gray-500 ring-2 grayscale brightness-50"
      }
      ${station.station_alerts.alerts.length > 0? ("ring ring-red-500 ring-2"): ("")}">
        <div class="flex-shrink-0 aspect-square h-full">
          <img 
            src="https://hazcams-data-prod.nyc3.digitaloceanspaces.com/thumbnails/${station.station_id}/small.jpg?cache=${Date.now()}"
            class="object-cover aspect-square h-full" 
            alt="Station Snapshot" 
            onerror="this.onerror=null; this.src='/fallback_thumbnail.jpg';"
          />
        </div>
        <div class="text-center flex flex-col py-1 px-2 font-bold mt-2 flex items-center justify-center flex-grow">
          <p>${station.station_name}</p>
          <p>${station.station_sponsor.name}</p>
          ${
            station.station_online
              ? "<p class='text-green-500'>ONLINE</p>"
              : "<p class='text-red-500'>Last Seen: " +
                getCurrentTime(
                  station.station_timestamp,
                  Intl.DateTimeFormat().resolvedOptions().timeZone,
                  true
                ) +
                "</p>"
          }
        </div>
      </div>
      <div class="marker-arrow absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 w-0 h-0 border-l-8 border-l-transparent border-r-8 border-r-transparent border-t-8 border-t-white dark:border-t-gray-500"></div>
    </a>
  `;

  
  return markerElement;
};


// Function to update the stations layer
export const updateStationsLayer = async (map, enabled, stations) => {
  // Exit if the layer is not enabled
  if (!enabled) {
    markers.forEach((marker) => marker.remove());
    markers.length = 0; // Clear the array
    return;
  }

  // Check if the source exists
  let source = map.getSource("stations-source");

  if (source) {
    // Update the existing source's data
    source.setData({
      type: "FeatureCollection",
      features: stations.map((station) => ({
        type: "Feature",
        properties: {
          station_id: station.station_id,
          // Add any other properties you need
        },
        geometry: {
          type: "Point",
          coordinates: [
            parseFloat(station.station_geo.lon),
            parseFloat(station.station_geo.lat),
          ],
        },
      })),
    });
  } else {
    // Add a new source for the stations if it doesn't exist
    map.addSource("stations-source", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: stations.map((station) => ({
          type: "Feature",
          properties: {
            station_id: station.station_id,
            // Add any other properties you need
          },
          geometry: {
            type: "Point",
            coordinates: [
              parseFloat(station.station_geo.lon),
              parseFloat(station.station_geo.lat),
            ],
          },
        })),
      },
    });
  }

  // Add a new layer for the stations
  /*
    map.addLayer({
      id: "stations-layer",
      type: "symbol",
      source: "stations-source",
      layout: {
        "icon-image": "marker-icon", // Default icon, not used here
        "icon-size": 1,
      },
    });
  }*/

  // Remove existing markers
  markers.forEach((marker) => marker.remove());
  markers.length = 0; // Clear the array

  // Sort stations by viewers
  //stations.sort((a, b) => a.viewers - b.viewers);

  // Create new markers
  stations.reverse().forEach((station) => {
    const markerElement = createStyledMarker(station);
    const marker = new maplibregl.Marker({
      element: markerElement,
    })
      .setLngLat([
        parseFloat(station.station_geo.lon),
        parseFloat(station.station_geo.lat),
      ])
      .addTo(map);

    // Store marker reference
    markers.push(marker);
  });
};
