//@ts-nocheck

import React, { useState, useEffect, useRef } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
//import "./VideoJS.css";
import { currentTime } from "@influxdata/influxdb-client";
import { ElectricScooterOutlined } from "@mui/icons-material";

const VideoJS = (props) => {
  const [streamIndex, setStreamIndex] = useState(1); // State for stream selection
  const [playerData, setPlayerData] = useState();

  const playback_error_count = useRef(0);

  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const uriRef = useRef(null);
  const bufferingTimeoutRef = useRef(null); // Ref for buffering timeout
  const statsIntervalRef = useRef(null); // Ref for stats interval
  const { options, onReady, forceStream, updatePlayerStats } = props;

  useEffect(() => {
    var player_data = {
      hls_bandwidth: 0,
      current_time: 0,
      buffered_time:0,
      network_state: 0,
      ready_state: 0,
      current_stream: options.sources[streamIndex],
    };
    setPlayerData(player_data);
    uriRef.current = options.sources[streamIndex];
    if (!playerRef.current) {
      console.log("player");
      const videoElement = document.createElement("video");
      videoElement.classList.add("video-js", "vjs-big-play-centered");

      videoRef.current.appendChild(videoElement);

      

      const player = (playerRef.current = videojs(videoElement, options, () => {
        videojs.log("player is ready");
        onReady && onReady(player);

        

        // Add event listeners for buffering
        player.on("waiting", () => {
          console.log("waiting");
        }); //set to SD if buffering

        player.on("error", () =>  {
          console.log("error");
          if(streamIndex != 1){
            setStreamIndex(0);
          }
        }

        );

        player.on("play", () => {
          statsIntervalRef.current = setInterval(logStats, 250);
        });

        const sources = options.sources || [];

        if (forceStream != null) {
          player.src(sources[forceStream]);
        } else {
          player.src(sources[1]);
        }
      }));
    } else {
      const player = playerRef.current;

      console.log("player new");

      player.autoplay(options.autoplay);

      // Update source based on streamIndex

      const sources = options.sources || [];

      if (forceStream != null) {
        player.src(sources[forceStream]);
      } else {
        player.src(sources[streamIndex]);
      }
    }

    return () => {

      

      const player = playerRef.current;

      // Clear the stats interval
      if (statsIntervalRef.current) {
        clearInterval(statsIntervalRef.current);
        statsIntervalRef.current = null;
      }
    };
  }, [options, streamIndex, forceStream]); // Re-run when streamIndex changes

  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  const handleStreamChange = (newStreamIndex) => {
    if (statsIntervalRef.current) {
      clearInterval(statsIntervalRef.current);
      statsIntervalRef.current = null;
    }
    setStreamIndex(newStreamIndex);
  };

  

  useEffect(() => {
    if (playerData) {
      updatePlayerStats(playerData);
    }
    //if(false){
    if (playerData && playerData.ready_state != 4) {
      //not playing
      playback_error_count.current = playback_error_count.current + 1;
      if (playback_error_count.current > 100) {
        setStreamIndex(0); //go to SD
        playback_error_count.current = 0; //reset error count;
      }
    } else {
      if (playback_error_count.current > 0) {
        playback_error_count.current = playback_error_count.current - 1;
      }
    }
  }, [playerData]);

  const logStats = () => {
    //return;
    const player = playerRef.current;
    if (player) {
      const tech = player.tech_.el_;
      if (tech) {
        // Access quality levels
        const qualityLevels = player.qualityLevels
          ? player.qualityLevels()
          : [];
        //console.log('Quality Levels:', qualityLevels); // Debug logging



        // Extract HLS bandwidth
        let hlsBandwidth = 0;
        if (qualityLevels.length > 0) {
          const currentQualityLevel =
            qualityLevels[player.qualityLevels().selectedIndex];
          hlsBandwidth = currentQualityLevel ? currentQualityLevel.bitrate : 0;
        }

        // Log player data
        var player_data = {
          hls_bandwidth: hlsBandwidth || 0,
          current_time: player.currentTime(),
          buffered_time:player?.buffered()?.length > 0 ? player?.buffered()?.end(0) : 0,
          network_state: tech.networkState,
          ready_state: tech.readyState,
          current_stream: uriRef.current,
        };
        setPlayerData(player_data);
      }
      else{
       
      }
    }
    else{
     
    }
  };

  return (
    <div className="relative w-fill lg:h-dvh h-full">
      <div ref={videoRef} className="absolute top-0 left-0 w-full h-full p-1" />
      <div className="absolute bottom-1 md:bottom-5 right-2 md:right-5 flex flex-col items-end  mix-blend-plus-darker text-white ">
        <p className="text-sm">WXLOGIC</p>
        <p className="text-xs uppercase">Unauthorized use is prohibited</p>
      </div>
    </div> 
  );
};

export default VideoJS;
