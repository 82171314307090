//@ts-nocheck
import React, { useState, useEffect } from "react";
import { MdPeople, MdAccessTime, MdArrowDropUp   } from "react-icons/md";
import StationStatsPlate from "./stationStatsPlate";
import { ChevronRightIcon } from '@heroicons/react/20/solid';

const formatDateTime = (timestamp) => {
  if (!timestamp) return '';
  const date = new Date(timestamp);
  return date.toLocaleString(); // You can customize the format here
};

const formatLocalTime = (timezone) => {
  if (!timezone) return '';
  
  const date = new Date();
  const options = {
    timeZone: timezone,
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    second: '2-digit',
    hour12: true, // Use 24-hour time
  };
  
  return new Intl.DateTimeFormat('en-US', options).format(date);
};

export default function StationSettingsPlate({
  stationData,
  latencyData,
  videoData,
  realtimeData,
  children,
  enabled,
  enabledCallback,
}) {

  const [showStats, setShowStats] = useState(false);

  const [currentLocalTime, setCurrentLocalTime] = useState(() =>
    formatLocalTime(stationData.timezone)
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentLocalTime(formatLocalTime(stationData.timezone));
    }, 250);

    return () => clearInterval(interval);
  }, [stationData.timezone]);

  useEffect(() => {
    if(enabled == false){
      setShowStats(false);
    }
  }, [enabled]);

  const share = () => {
    if (navigator.share) {
      navigator
        .share({
          title: document.title,
          url: window.location.href,
        })
        .then(() => console.log("Successfully shared"))
        .catch((error) =>
          console.error("Something went wrong sharing the URL", error)
        );
    } else {
      console.error("Web Share API is not supported in your browser.");
    }
  };

  return (
    <div
      className={`transition-all duration-500 absolute top-16 left-2 z-30  ${
        enabled ? "" : "hidden"
      }`}>
        <StationStatsPlate stationData={stationData} videoData={videoData} latencyData={latencyData} realtimeData={realtimeData} enabled={showStats} enabledCallback={setShowStats}/>
      <div className={`relative bg-black backdrop-blur-lg bg-gray-500 bg-opacity-50 rounded-lg text-white font-bold p-4 w-96 ${showStats? ('invisible md:visible') : ('')}`}>
        <button
          type="button"
          className="absolute top-4 right-4 text-white  dark:text-white bg-gray-700 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center"
          data-modal-hide="default-modal"
          onClick={() => enabledCallback(false)}>
          <svg
            className="w-3 h-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14">
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
          <span className="sr-only">Close modal</span>
        </button>
        <p>Settings</p>
        <div className="w-full h-4"></div>
        
        <div
          className={`relative bg-gray-600 my-2 transition-all duration-300 grid p-2 text-left w-full grid grid-cols-2  shadow-md rounded-md items-center`}>
          <div className="col p-1">
            <p>Stats</p>
            <p className="text-xs">See detailed information about how this client and station are performing</p>
          </div>
          <div>
            <button
              className="inline-flex w-full justify-center gap-x-1.5 rounded-md dark:bg-gray-500 dark:hover:bg-gray-500 dark:text-white bg-gray-200 hover:bg-gray-50 text-black bg-opacity-75  px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm "
              onClick={() => setShowStats(true)}>
              Stats
              <ChevronRightIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
            </button>
          </div>
        </div>
        <div
          className={`relative bg-gray-600 my-2 transition-all duration-300 grid p-2 text-left w-full grid grid-cols-2  shadow-md rounded-md items-center`}>
          <div className="col p-1">
            <p>Share</p>
            <p className="text-xs">Share this live feed</p>
          </div>
          <div>
            <button
              className="inline-flex w-full justify-center gap-x-1.5 rounded-md dark:bg-gray-500 dark:hover:bg-gray-500 dark:text-white bg-gray-200 hover:bg-gray-50 text-black bg-opacity-75  px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm "
              onClick={share}>
              Share
            </button>
          </div>
        </div>
        {children}
      </div>
    </div>
  );
}
