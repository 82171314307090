//@ts-nocheck
import React, { useRef, useEffect, useState } from "react";
import Map from "../Map/controllable_map";

export default function Map_Plate({grid_size, config, location, theme }) {
  
  const [radarTimestamp, setRadarTimestamp] = useState("RADAR INVALID");

  
  const size = ["col-span-1", "col-span-2", "col-span-3"];

 


  return (
    <div
      className={`relative h-64  ${size[grid_size]}  rounded-md ${config.panel_padding} ${config.panel_margin}  justify-between bg-gray-100 dark:bg-gray-800 overflow-hidden`}>
      <div className=" flex absolute top-0 left-0 w-full h-full justify-center items-center">
      <Map  className="absolute w-full h-full" options={{
          interactive: false,
          lat: location.lat,
          lon: location.lon,
          zoom: 8,
          theme: theme,
          radar: true,
          showRadarTimestamp: true,
          attribution: false,
        }}/>
        <span className="absolute flex h-3 w-3 z-40">
          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-gray-800 dark:bg-gray-100 opacity-75"></span>
          <span className="relative inline-flex rounded-full h-3 w-3 bg-gray-600 dark:bg-gray-200"></span>
        </span>
      </div>
      
    </div>
  );
}
