//@ts-nocheck
import React, { useEffect, useState } from "react";
import VideoJS from "./VideoJS";
import "./VideoJS.css";


export default function HLSPlayer ({station_id, video_server, video_resolution, updatePlayerStats}){
  const playerRef = React.useRef(null);
  const [playerStatus, setPlayerStatus] = useState(true);
  const [retry, setRetry] = useState(0);
  //const station_id = "lebeau-la-us-001";

  //http://165.227.127.47:8888/${station_id}-sd/index.m3u8




  const videoJsOptions = React.useMemo(
    () => ({
      autoplay: true,
      muted: true,
      fill: true,
      playsinline: true,
      controls: true,
      liveui: true,
      liveTracker: true,
      poster: `https://hazcams-data-prod.nyc3.digitaloceanspaces.com/thumbnails/${station_id}/medium.jpg?cache=${Date.now()}`,
      sources: [
        {
          src: `${video_server}/${station_id}-sd/index.m3u8`,
          type: "application/x-mpegURL",
        },
        {
          src: `${video_server}/${station_id}/index.m3u8`,
          type: "application/x-mpegURL",
        }        
      ]
    }),
    [video_server, station_id]
  );

  const handlePlayerReady = (player) => {
    console.log("state_Change");
    playerRef.current = player;

    player.on("waiting", () => {
      setPlayerStatus(true);
    });

    player.on("dispose", () => {
      setPlayerStatus(false);
    });

    player.on("error", () => {
      setPlayerStatus(false);
    });

    player.on("play", () => {
      setPlayerStatus(true);
    });

    player.on("playing", () => {
      setPlayerStatus(true);
    });

  }

  const handleRetry = () => {
    setPlayerStatus(true);
    setRetry((prev) => prev + 1);
  };

  useEffect(() => {
    handleRetry();
  }, [video_server, video_resolution]);


 

  return (
    <div className="w-full lg:h-full lg:aspect-auto aspect-video rounded-lg overflow-hidden">
      {playerStatus ? (
        <VideoJS options={videoJsOptions} onReady={handlePlayerReady} forceStream={video_resolution} updatePlayerStats={updatePlayerStats}/>
      ) : (
        <div className="relative w-full lg:h-dvh h-full z-10">
          <object
            className="absolute top-0 left-0 w-full h-full p-1 rounded-xl"
            data={`https://hazcams-data-prod.nyc3.digitaloceanspaces.com/thumbnails/${station_id}/medium.jpg?cache=${Date.now()}`}
            type="image/jpg">
            <img
            className="w-full h-full"
              src={"/fallback_background.gif"}
              alt="Fallback Background"
            />
          </object>

          <div className="absolute m-1 rounded-lg text-gray-700 dark:text-white top-0 bottom-0 left-0 right-0 flex flex-col items-center justify-center text-lg font-black backdrop-blur-lg backdrop-brightness-200 dark:backdrop-brightness-50 bg-gray-200/75 dark:bg-gray-800/75">
            <div>Video Link Down</div>
            <button
              onClick={handleRetry}
              className="mt-4 px-4 py-2 bg-gray-600 hover:bg-gray-700 rounded-md text-white">
              Retry
            </button>
          </div>
        </div>
      )}
    </div>
  );
};


