//@ts-nocheck
import React, { useState, useEffect, useRef } from "react";
import { MdPeople, MdAccessTime, MdArrowDropUp, MdLoop  } from "react-icons/md";

const pingServer = (url, callback) => {
  const ping = async () => {
    try {
      const startTime = Date.now();

      // Send a fetch request to the server
      await fetch(url, {
        method: "GET",
        cache: "no-cache",
      });

      const endTime = Date.now();
      const pingTime = endTime - startTime;

      // Call the callback with the ping time
      callback(pingTime);
    } catch (error) {
      // Call the callback with null if there's an error
      callback(null);
    }
  };

  // Initial ping
  ping();

  // Ping every 10 seconds
  const intervalId = setInterval(ping, 10000);

  // Return a function to stop the auto-update when needed
  return () => clearInterval(intervalId);
};


const formatDateTime = (timestamp) => {
  if (!timestamp) return "";
  const date = new Date(timestamp);
  return date.toLocaleString(); // You can customize the format here
};

const formatLocalTime = (timezone) => {
  if (!timezone) return "";

  const date = new Date();
  const options = {
    timeZone: timezone,
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
    second: "2-digit",
    hour12: true, // Use 24-hour time
  };

  return new Intl.DateTimeFormat("en-US", options).format(date);
};

export default function StationStatsPlate({
  stationData,
  latencyData,
  videoData,
  realtimeData,
  enabled,
  enabledCallback,
}) {
  const videoLatency = useRef(-1);
  const clientLatency = useRef(-1);

  const pingCallback = (value) => {
    clientLatency.current = value || -1;
  };

  useEffect(() => {
    const stopPinging = pingServer("https://realtime.hazcams.wxlogic.com/ping", (pingTime) => {
      clientLatency.current = pingTime;
    });
  }, []);

  useEffect(() => {
    if (videoData?.buffered_time && videoData?.current_time) {
      if (
        videoLatency.current <=
        Math.round(videoData.buffered_time) - Math.round(videoData.current_time)
      ) {
        videoLatency.current =
          Math.round(videoData.buffered_time) -
          Math.round(videoData.current_time) +
          1;
      }
    }
  }, [videoData]);

  const [currentLocalTime, setCurrentLocalTime] = useState(() =>
    formatLocalTime(stationData.timezone)
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentLocalTime(formatLocalTime(stationData.timezone));
    }, 250);

    return () => clearInterval(interval);
  }, [stationData.timezone]);

  return (
    <div
      className={`transition-all duration-500 absolute top-0 md:left-96 z-50 md:px-4  ${
        enabled ? "" : "hidden"
      }`}>
      <div className="relative bg-black backdrop-blur-lg bg-gray-500 bg-opacity-50 rounded-lg text-white font-bold p-4 w-96">
        <button
          type="button"
          className="absolute top-4 right-4 text-white  dark:text-white bg-gray-700 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center"
          data-modal-hide="default-modal"
          onClick={() => enabledCallback(false)}>
          <svg
            className="w-3 h-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14">
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
          <span className="sr-only">Close modal</span>
        </button>
        <p>Stats</p>
        <div className="w-full h-4"></div>
        <div className="col">
          <div className="row">
            <div className="flex items-center justify-between line-clamp-1">
              <span className="mr-2">Current Viewers:</span>
              <div className="flex items-center">
                <span>{stationData.viewers || 1}</span>
                <MdPeople size={16} className="ml-1" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="flex items-center justify-between line-clamp-1">
              <span className="mr-2">Station Latency:</span>
              <div className="flex items-center">
                <span>
                  {latencyData?.value
                    ? latencyData.value + "ms"
                    : "Not Measured"}
                </span>
                {latencyData?.value ? (
                  <MdAccessTime size={16} className="mx-1" />
                ) : null}
                <span>
                  {" "}
                  {latencyData?.range ? latencyData.range.max + "ms" : null}
                </span>
                {latencyData?.range ? (
                  <MdArrowDropUp size={16} className="ml-1" />
                ) : null}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="flex items-center justify-between line-clamp-1">
              <span className="mr-2">Client Latency / TTT:</span>
              <div className="flex flex-row items-center">
                <span>{clientLatency.current}ms</span><MdAccessTime size={16} className="mx-1" />
                <span>{Math.round(clientLatency.current + latencyData?.value)}ms</span><MdLoop size={16} className="mx-1" />
              </div>
             
            </div>
          </div>
          <div className="row">
            <div className="flex items-center justify-between line-clamp-1">
              <span className="mr-2 w-1/2">Local Time:</span>
              <div className="flex items-center w-1/2">
                <span className="text-ellipsis overflow-hidden line-clamp-1">{currentLocalTime}</span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="flex items-center justify-between line-clamp-1">
              <span className="mr-2  w-1/2">Station Timestamp: </span>
              <div className="flex items-center w-1/2">
                <span className="text-ellipsis overflow-hidden line-clamp-1">{formatDateTime(stationData.timestamp)}</span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="flex items-center justify-between line-clamp-1">
              <span className="mr-2  w-1/2">Data Timestamp: </span>
              <div className="flex items-center w-1/2">
                <span className="text-ellipsis overflow-hidden line-clamp-1">{formatDateTime(stationData.data_timestamp)}</span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="flex items-center justify-between line-clamp-1">
              <span className="mr-2">Video Position: </span>
              <div className="flex items-center">
                <span>{Math.round(videoData?.current_time) + " Seconds" || "N/A"}</span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="flex items-center justify-between line-clamp-1">
              <span className="mr-2">Video Buffer: </span>
              <div className="flex items-center">
                <span>{Math.round(videoData?.buffered_time) + " Seconds" || "N/A"}</span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="flex items-center justify-between line-clamp-1">
              <span className="mr-2">Video Latency: </span>
              <div className="flex items-center">
                <span>{videoLatency.current} Seconds</span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="flex items-center justify-between line-clamp-1">
              <span className="mr-2">Video Bandwidth: </span>
              <div className="flex items-center">
                <span>
                  {videoData?.hls_bandwidth
                    ? videoData.hls_bandwidth < 1000000
                      ? `${Math.round(videoData.hls_bandwidth / 1000)} kbps`
                      : `${(videoData.hls_bandwidth / 1000000).toFixed(3)} mbps`
                    : "N/A"}
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="flex flex-row items-center justify-between ">
              <span className=" mr-2 w-1/2">Video URI: </span>
              <div className="flex items-center w-1/2">
                <span className="text-ellipsis overflow-hidden line-clamp-1">
                  {videoData?.current_stream.src || "NONE"}
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="flex items-center justify-between line-clamp-1">
              <span className="mr-2">Player Stats: </span>
              <div className="flex items-center w-1/2 justify-end">
                <span className="text-ellipsis overflow-hidden line-clamp-1 text-right">
                  {videoData?.network_state || 0} , {videoData?.ready_state || 0}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
